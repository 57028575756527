<template>
  <div class="set-service-box">
    <Form>
      <Field v-if="formInline.order_id" disabled v-model="formInline.order_id" label="订单ID"/>
      <template v-else>
        <Field @click="openPicker({key: 'service'})" readonly v-model="serviceValue" label="服务项目" placeholder="请选择服务项目"/>
        <Field v-model="formInline.mobile" label="用户手机" placeholder="请输入用户手机"/>
      </template>
      <Field v-model="formInline.service_mobile" label="服务手机" placeholder="请输入服务手机"/>
      <Field @click="openDateTimePicker()" readonly v-model="formInline.service_start_time" label="服务开始时间" placeholder="请选择服务开始时间"/>
      <Field @click="openDateTimePicker()" readonly v-model="formInline.service_end_time" label="服务结束时间" placeholder="请选择服务结束时间"/>
    </Form>
    <div class="btn-list flex">
      <Button :loading="submitLoading" loading-text="提交中..." class="btn-item flex-item" type="primary" @click="submit()">提交</Button>
    </div>
    <Popup v-model="showPicker" position="bottom">
      <Picker show-toolbar
              v-if="showPickerKey === 'service'"
              :columns="serviceItem"
              value-key="name"
              @confirm="onConfirm"
              @cancel="hidePicker"
      />
    </Popup>
    <MobileDateTimePicker :value.sync="showDateTimePicker" :defaultDate="defaultDate" @confirm="dateConfirm" />
  </div>
</template>

<script>
import { Form, Field, Popup, Picker, Button, Toast } from 'vant'
import { addOrderApi, serviceRecordDetailApi, setServiceTimeApi } from '@/api/order'
import { mapActions, mapState } from 'vuex'
import MobileDateTimePicker from '@/components/mobile/MobileDateTimePicker'
import dayjs from 'dayjs'

export default {
  name: 'MobileSetService',
  components: {
    Form,
    Field,
    Popup,
    Picker,
    MobileDateTimePicker,
    Button
  },
  computed: {
    ...mapState('selectOptions', ['serviceItem'])
  },
  data () {
    return {
      formInline: {
        order_id: '',
        mobile: '',
        service_mobile: '',
        service_item_id: '',
        service_start_time: '',
        service_end_time: ''
      },
      showPicker: false,
      serviceValue: '',
      showPickerKey: '',
      formKey: '',
      showDateTimePicker: false,
      defaultDate: [],
      submitLoading: false
    }
  },
  created () {
    this.getServiceItem()
    const { order_id } = this.$route.query
    if (order_id) {
      this.formInline.order_id = order_id
      this.getServiceRecordDetail()
    }
  },
  methods: {
    ...mapActions('selectOptions', ['getServiceItem']),
    async getServiceRecordDetail () {
      const { order_id } = this.formInline
      const data = await serviceRecordDetailApi({ order_id })
      if (data.code === 1) {
        if (data.data === null) return
        const detail = data.data
        const service_start_time = this.updateformInline(detail.start_time)
        const service_end_time = this.updateformInline(detail.end_time)
        this.formInline = {
          order_id,
          mobile: detail.client_mobile,
          service_mobile: detail.service_mobile,
          service_item_id: detail.id,
          service_start_time,
          service_end_time
        }
        this.defaultDate = [
          dayjs(service_start_time).toDate(),
          dayjs(service_end_time).toDate()
        ]
      }
    },
    /**
     * 时间戳转时间
     * @param date
     * @return {*}
     */
    updateformInline (date) {
      return this.$dateFormat(date, 'YYYY-MM-DD HH:mm:ss')
    },
    hidePicker () {
      this.showPicker = false
    },
    onConfirm (value) {
      this.serviceValue = value.name
      this.formInline.service_item_id = value.id
      this.hidePicker()
    },
    openPicker (pickerKey) {
      const { key } = pickerKey
      this.showPickerKey = key
      this.showPicker = true
    },
    openDateTimePicker () {
      this.showDateTimePicker = true
    },
    dateCancel () {
      this.hidePicker()
    },
    dateConfirm (value) {
      this.formInline.service_start_time = value.startTime
      this.formInline.service_end_time = value.endTime
      this.defaultDate = [dayjs(value.startTime).toDate(), dayjs(value.endTime).toDate()]
    },
    async submit () {
      this.submitLoading = true
      const { formInline } = this.$data
      try {
        if (formInline.order_id) {
          await setServiceTimeApi(formInline)
        } else {
          await addOrderApi(formInline)
        }
        Toast.success('提交成功，请返回。')
      } catch (e) {

      }
      this.submitLoading = false
    }
  }
}
</script>

<style scoped lang="scss">
$btn-height: 50px;
.set-service-box {
  min-height: 100vh;
  position: relative;
  padding-bottom: $btn-height;
}
.btn-list {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0 10px;
  .btn-item {
    height: $btn-height;
    margin-right: 20px;
    &:last-child {
      margin-right: 0;
    }
  }
}
</style>
