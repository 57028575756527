<template>
  <Popup v-model="show" position="left" safe-area-inset-bottom class="calendar" @closed="handleCalendarClosed"
             @opened="handleCalendarOpened">
    <Calendar
        ref="calendar"
        :poppable="false"
        allow-same-day
        color="#101a52"
        type="range"
        @confirm="onCalendarConfirm"
        @select="onCalendarSelect"
        :default-date="defaultDate"
        :minDate="minDate"
        :maxDate="maxDate"
    >
      <div slot="title" class="calendar-header">
        <Icon class="icon" name="arrow-left" @click="handleBack"></Icon>
        <div class="title">日期选择</div>
        <span class="clear" v-show="startDate" @click.stop="handleClearDate">清除</span>
      </div>
      <div slot="footer" class="calendar-footer dis-flex flex-y-center">
        <div class="flex-box f-14 text">
          <p>开始时间：{{ start }}</p>
          <p>结束时间：{{ end }}</p>
        </div>
        <Button color="#101a52" :disabled="isDisabled" @click="onCalendarConfirm">确定</Button>
      </div>
    </Calendar>
    <Popup v-model="showPicker" round position="bottom" @opened="handlePopupOpen">
      <Picker
          ref="picker"
          show-toolbar
          :columns="columns"
          @cancel="showPicker = false"
          @change="onPickerChange"
          swipe-duration="500"
          visible-item-count="5"
      >
        <div class="picker-title">{{ pickerText }}</div>
      </Picker>
    </Popup>
  </Popup>
</template>

<script>
import { Picker, Popup, Calendar, Button, Icon } from 'vant'
import dayjs from 'dayjs'
function initData () {
  return {
    pickerText: '开始时间',
    isDisabled: true,
    showPicker: false,
    startDate: '',
    endDate: '',
    startTime: '',
    endTime: ''
  }
}
export default {
  name: 'MobileDateTimePicker',
  components: {
    Picker,
    Popup,
    Calendar,
    Button,
    Icon
  },
  props: {
    minDate: {
      type: Date,
      default: () => dayjs().subtract(90, 'day').toDate()
    },
    maxDate: {
      type: Date,
      default: () => dayjs().toDate()
    },
    value: {
      type: Boolean,
      default: true
    },
    defaultDate: {
      type: Array,
      default: () => ([])
    }
  },
  data () {
    return {
      columns: [
        {
          values: ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'],
          defaultIndex: 0
        },
        {
          values: ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31', '32', '33', '34', '35', '36', '37', '38', '39', '40', '41', '42', '43', '44', '45', '46', '47', '48', '49', '50', '51', '52', '53', '54', '55', '56', '57', '58', '59'],
          defaultIndex: 0
        },
        {
          values: ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31', '32', '33', '34', '35', '36', '37', '38', '39', '40', '41', '42', '43', '44', '45', '46', '47', '48', '49', '50', '51', '52', '53', '54', '55', '56', '57', '58', '59'],
          defaultIndex: 0
        }
      ],
      ...initData()
    }
  },
  watch: {
    startDate () {
      if (!this.startDate || !this.endDate) {
        this.isDisabled = true
      }
    },
    value (val) {
      if (val && this.$refs.calendar) {
        this.$refs.calendar.reset()
        this.updateDefaultDate(this.defaultDate)
      }
    },
    defaultDate (val) {
      this.updateDefaultDate(val)
    },
    endTime (val) {
      if (!val) {
        return
      }
      /* if (dayjs(this.end).isBefore(dayjs(this.start))) {
        this.startTime = this.endTime
      } */
      this.isDisabled = false
    }
  },
  computed: {
    start () {
      return this.startDate + ' ' + this.startTime
    },
    end () {
      return this.endDate + ' ' + this.endTime
    },
    show: {
      set (val) {
        this.$emit('update:value', val)
      },
      get () {
        return this.value
      }
    }
  },
  methods: {
    // 清除日历数据
    handleClearDate () {
      this.$refs.calendar.reset()
      Object.assign(this.$data, {
        pickerText: '开始时间',
        isDisabled: true,
        startDate: '',
        endDate: '',
        startTime: '',
        endTime: ''
      })
    },
    handleCalendarOpened () {
      const calendarBody = document.getElementsByClassName('van-calendar__body')[0]
      calendarBody.scrollTo({
        top: calendarBody.scrollHeight,
        behavior: 'smooth'
      })
    },
    showCalender () {
      this.show = true
    },
    handleCalendarClosed () {
      this.$refs.calendar.reset()
      Object.assign(this.$data, initData())
    },
    handleBack () {
      this.show = false
    },
    dayjsTime (date) {
      return date ? this.$dateFormat(date, 'YYYY-MM-DD') : ''
    },
    setPickerIndexes (time) {
      const { columns } = this.$data
      const indexes = []
      const timeArr = dayjs(time).format('HH:mm:ss').split(':')
      /* timeArr.forEach((item, index) => {
        indexes[index] = columns[index].values.findIndex(columnsItem => columnsItem === item)
      }) */
      return timeArr
    },
    handlePopupOpen () {
      const startTimeIndexes = this.startTime ? this.startTime.split(':') : this.setPickerIndexes(this.defaultDate[0])
      const endTimeIndexes = this.endTime ? this.endTime.split(':') : this.setPickerIndexes(this.defaultDate[1])
      if (this.pickerText === '开始时间') {
        this.$refs.picker.setIndexes(startTimeIndexes)
      } else if (this.pickerText === '结束时间') {
        this.$refs.picker.setIndexes(endTimeIndexes)
      }
      const value = this.$refs.picker.getValues()
      const str = value.join(':')
      if (this.pickerText === '开始时间') {
        this.startTime = str
        return
      }
      this.endTime = str
    },
    onPickerChange (el, value) {
      const str = value.join(':')
      if (this.pickerText === '开始时间') {
        this.startTime = str
        return
      }
      this.endTime = str
    },
    onCalendarConfirm () {
      this.$emit('confirm', {
        startTime: this.start,
        endTime: this.end
      })
      this.show = false
    },
    onCalendarSelect (val) {
      if (val[1]) {
        this.endDate = dayjs(val[1]).format('YYYY-MM-DD')
        this.pickerText = '结束时间'
      } else {
        /* if (this.end) {
          this.endDate = ''
          this.endTime = ''
        } */
        this.startDate = dayjs(val[0]).format('YYYY-MM-DD')
        this.pickerText = '开始时间'
      }
      this.showPicker = true
    },
    updateDefaultDate (val) {
      this.startDate = this.dayjsTime(val[0])
      this.endDate = this.dayjsTime(val[1])
      this.startTime = dayjs(val[0]).format('HH:mm:ss')
      this.endTime = dayjs(val[1]).format('HH:mm:ss')
    }
  }
}
</script>

<style scoped lang="scss">
.calendar {
  width: 100%;
  height: 100%;

  .calendar-header {
    position: relative;

    .icon {
      position: absolute;
      left: 8px;
      top: 50%;
      width: 24px;
      height: 24px;
      line-height: 24px;
      padding: 0 6px;
      transform: translateY(-50%);
    }

    .title {
      text-align: center;
    }

    .clear {
      position: absolute;
      right: 8px;
      top: 50%;
      height: 24px;
      line-height: 24px;
      padding: 0 6px;
      transform: translateY(-50%);
    }
  }

  .calendar-footer {
    padding: 6px 0;

    .text {
      line-height: 20px;
    }
  }

  /deep/ .van-picker__toolbar {
    justify-content: center;
    font-weight: 500;
  }
}
</style>
